export enum Language {
  EN = "English",
  BM = "Bahasa Malaysia",
}

export enum Role {
  STUDENT = "Student",
  TEACHER = "Teacher",
}
/**
 * @collection users
 * @description User of Q-CaT CPR App
 *
 * id - Firebase Auth Id
 * name - Name of the user
 * code - Unique Code of the user
 * email - Email of the user
 * language - Language Enum Type
 * role - Role Enum Type
 * studentId - List of students' Firebase UUID
 * teacher - True boolean if the role is a teacher for firebase security rules
 * createdAt - User creation date
 */
export interface UserModel {
  id: string;
  name: string;
  code: string;
  email: string;
  language: keyof typeof Language;
  role: keyof typeof Role;
  studentId?: string[];
  teacher?: boolean;
  createdAt: Date;
}
