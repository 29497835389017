import { combineReducers } from "redux";
import authReducer from "./authReducer";
import recordReducer from "./recordReducer";
import userReducer from "./userReducer";

const rootReducer = combineReducers({
  authStore: authReducer,
  userStore: userReducer,
  recordStore: recordReducer,
});

export default rootReducer;
