import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import Table, { TableHeader } from "../components/base/Table";
import { recordDataLimit } from "../store/actions/recordActions";
import { authStateInterface } from "../store/reducers/authReducer";
import { recordStateInterface } from "../store/reducers/recordReducer";
import { userStateInterface } from "../store/reducers/userReducer";
import { DashboardPage } from "./LeftNav";

interface Props {
  authStore: authStateInterface;
  userStore: userStateInterface;
  recordStore: recordStateInterface;
  pageIndex: number;
  handlePathNavigation: (path: DashboardPage, id?: string) => void;
  getRecordWithPagination: (paginationStartAt: string | Date) => void;
  handleUpdatePageIndex: (pageIndex: number) => void;
}

class Record extends Component<Props> {
  handleSelectedData = (id: string) => {
    this.props.handlePathNavigation(DashboardPage.RECORDDETAILS, id);
  };

  handlePagination = (value: string) => {
    if (value === "next") {
      if (
        this.props.recordStore.lastCursor &&
        this.props.pageIndex * recordDataLimit + 1 >
          this.props.recordStore.records.length
      ) {
        this.props.getRecordWithPagination(this.props.recordStore.lastCursor);
      }
      this.props.handleUpdatePageIndex(this.props.pageIndex + 1);
    } else {
      this.props.handleUpdatePageIndex(this.props.pageIndex - 1);
    }
  };

  renderData = () => {
    const dataList: any[] = [];
    if (this.props.recordStore.records.length > 0) {
      this.props.recordStore.records.map((eachRecord) => {
        this.props.userStore.users.map((eachUser) => {
          if (eachUser.id === eachRecord.userId) {
            dataList.push({
              id: eachRecord.id,
              name: eachRecord.name,
              email: eachUser.email,
              userId: eachRecord.userId,
              createdAt: moment(eachRecord.createdAt.seconds * 1000).format(
                "DD/MM/YYYY"
              ),
            });
          }
          return null;
        });
        return null;
      });
    }
    return dataList;
  };

  render() {
    const headers: TableHeader[] = [
      {
        key: "id",
        title: "Id",
      },

      {
        key: "name",
        title: "Name",
      },
      {
        key: "email",
        title: "User Email",
      },
      {
        key: "createdAt",
        title: "Created At",
      },
    ];

    return (
      <div className="mt-8">
        <div className="max-w-6xl mx-auto my-4 px-4 sm:px-6 lg:px-8">
          <div className="flex items-center">
            <h3 className="text-xl leading-6 font-bold text-gray-900">
              Records
            </h3>
          </div>
        </div>

        <Table
          dataLimit={recordDataLimit}
          header={headers}
          loading={this.props.recordStore.loading}
          pageIndex={this.props.pageIndex}
          lastCursor={this.props.recordStore.lastCursor}
          data={this.renderData()}
          onClickRow={this.handleSelectedData}
          handlePage={this.handlePagination}
          newTabUrl="/dashboard/recordDetails?data="
        />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
    recordStore: state.recordStore,
    userStore: state.userStore,
  };
};

export default connect(mapStateToProps)(Record);
