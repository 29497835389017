import { ChevronLeftIcon } from "@heroicons/react/outline";
import { ApexOptions } from "apexcharts";
import moment from "moment";
import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import { RecordModel } from "../models/Record";
import { getRecord } from "../store/actions/recordActions";
import {
  AnalyticsAttribute,
  CompressionPerMinute,
} from "../store/reducers/recordReducer";
import { DashboardPage } from "./LeftNav";

interface State {
  record: RecordModel;

  analyticsAttribute: AnalyticsAttribute;
  compressionPerMinute: CompressionPerMinute[];
  ratePerMinute: number[];
}

interface Props {
  handlePathNavigation: (page: DashboardPage) => void;
}

export class RecordDetails extends Component<Props> {
  state: State = {
    record: {
      id: "",
      name: "",
      userId: "",
      logs: [],
      createdAt: moment().toDate(),
    },
    analyticsAttribute: {
      baseline: 0,
      successful: 0,
      deep: 0,
      cpm: 0,
      score: 0,
    },
    compressionPerMinute: [],
    ratePerMinute: [],
  };

  componentDidMount = async () => {
    const searchParams = new URLSearchParams(window.location.search);
    const selectedId = searchParams.get("data");
    if (selectedId) {
      const recordData = await getRecord(selectedId);
      if (typeof recordData === "string") {
        this.handleBack();
      } else {
        this.setState(
          {
            record: recordData,
          },
          () => {
            this.handleFilterAnalyticsResult();
          }
        );
      }
    } else {
      this.handleBack();
    }
  };

  handleBack = () => {
    this.props.handlePathNavigation(DashboardPage.RECORD);
  };

  handleFilterAnalyticsResult = () => {
    let analyticsResult: AnalyticsAttribute = {
      baseline: 0,
      successful: 0,
      deep: 0,
      score: 0,
      cpm: 0,
    };

    //INFO : Compression Analytics
    let compressionTimeStamp = this.state.record ? this.state.record.logs : [];
    if (compressionTimeStamp.length > 0) {
      compressionTimeStamp.map((eachCompressionTimeStamp: any) => {
        if (eachCompressionTimeStamp.value < 5) {
          analyticsResult.baseline += 1;
        } else if (eachCompressionTimeStamp.value <= 6) {
          analyticsResult.successful += 1;
        } else {
          analyticsResult.deep += 1;
        }
        return null;
      });
    }

    //INFO : Compression Per Minute
    let totalRatePerCompression = 0;
    let beatPerMinute = 0;
    if (compressionTimeStamp.length > 0) {
      for (let i = 0; i < compressionTimeStamp.length; i++) {
        let previousValue = 0;
        if (i !== 0) {
          previousValue = compressionTimeStamp[i - 1].time;
        }
        totalRatePerCompression += compressionTimeStamp[i].time - previousValue;
      }

      totalRatePerCompression =
        totalRatePerCompression / compressionTimeStamp.length;
      beatPerMinute = 60 / totalRatePerCompression;
      if (beatPerMinute > 240) {
        beatPerMinute = 240;
      }
    }
    analyticsResult.cpm = Number(beatPerMinute.toFixed(1));

    //INFO : Score
    let totalDurationElapsed = 1;
    if (compressionTimeStamp.length > 0) {
      let lastAnalyticsTime =
        compressionTimeStamp[compressionTimeStamp.length - 1].time;
      totalDurationElapsed = Math.ceil(lastAnalyticsTime / 60);
    }

    let totalCompression =
      analyticsResult.baseline +
      analyticsResult.successful +
      analyticsResult.deep;
    let totalPerfectCompression = 120 * totalDurationElapsed;

    let rateScore =
      (1 -
        Math.abs(totalCompression - totalPerfectCompression) /
          totalPerfectCompression) *
      50;
    let compressionScore = (analyticsResult.successful / totalCompression) * 50;
    analyticsResult.score = Number((rateScore + compressionScore).toFixed(1));

    //INFO : Compression Per Minute Analytics
    let allCompressionPerMinute = [];
    for (let i = 0; i < totalDurationElapsed; i++) {
      let minuteRecord = {
        lowCompression: 0,
        normalCompression: 0,
        deepCompression: 0,
      };
      compressionTimeStamp.map(
        (eachCompressionTimeStamp: { time: number; value: number }) => {
          if (
            eachCompressionTimeStamp.time < 60 * (i + 1) &&
            eachCompressionTimeStamp.time > 60 * i
          ) {
            if (eachCompressionTimeStamp.value < 5) {
              minuteRecord.lowCompression += 1;
            } else if (eachCompressionTimeStamp.value <= 6) {
              minuteRecord.normalCompression += 1;
            } else {
              minuteRecord.deepCompression += 1;
            }
          }
          return null;
        }
      );
      allCompressionPerMinute.push(minuteRecord);
    }

    //INFO : Rate Per Minute Analytics
    let allRatePerMinute = [];
    for (let i = 0; i < totalDurationElapsed; i++) {
      let minuteRecord = {
        rate: 0,
      };
      let index = 0;
      let totalRatePerCompressionInAMinute = 0;
      if (compressionTimeStamp.length > 0) {
        for (let j = 0; j < compressionTimeStamp.length; j++) {
          if (
            compressionTimeStamp[j].time < 60 * (i + 1) &&
            compressionTimeStamp[j].time > 60 * i
          ) {
            let previousValue = 60 * i;
            if (index !== 0) {
              previousValue = compressionTimeStamp[j - 1].time;
            }
            index += 1;

            totalRatePerCompressionInAMinute +=
              compressionTimeStamp[j].time - previousValue;
          }
        }
      }

      if (index !== 0) {
        totalRatePerCompressionInAMinute =
          totalRatePerCompressionInAMinute / index;
        minuteRecord.rate = 60 / totalRatePerCompressionInAMinute;
      }
      if (minuteRecord.rate > 240) {
        minuteRecord.rate = 240;
      }
      allRatePerMinute.push(minuteRecord);
    }

    if (totalCompression) {
      this.setState({
        analyticsAttribute: analyticsResult,
        compressionPerMinute: allCompressionPerMinute,
        ratePerMinute: allRatePerMinute,
      });
    }
  };

  renderDepthGraph = () => {
    const depthOptions: ApexOptions = {
      chart: {
        type: "bar",
        stacked: true,
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      stroke: {
        colors: ["#fff"],
      },
      yaxis: {
        title: {
          text: "Time",
        },
        labels: {
          formatter: function (val) {
            return val + "";
          },
        },
      },
      xaxis: {
        categories: [1, 2],
      },
      title: {
        text: "Compression Depth Analytics (Time vs Compression)",
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
      },
      colors: ["rgb(255,212,38)", "rgb(48,219,91)", "rgb(255,105,97)"],
    };

    let lessCompression: number[] = [];
    let normalCompression: number[] = [];
    let deepCompression: number[] = [];

    this.state.compressionPerMinute.map(
      (eachData: CompressionPerMinute, index: number) => {
        lessCompression.push(eachData.lowCompression);
        normalCompression.push(eachData.normalCompression);
        deepCompression.push(eachData.deepCompression);
        return null;
      }
    );

    const depthSeries = [
      {
        name: "Less than 5cm",
        data: lessCompression,
      },
      {
        name: "5-6cm",
        data: normalCompression,
      },
      {
        name: "More than 6cm",
        data: deepCompression,
      },
    ];
    return (
      <ReactApexChart
        options={depthOptions}
        series={depthSeries}
        type="bar"
        height={250}
      />
    );
  };

  renderRateGraph = () => {
    const rateOptions: ApexOptions = {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      yaxis: {
        title: {
          text: "Time",
        },
        labels: {
          formatter: function (val) {
            return val + "cpm";
          },
        },
      },
      xaxis: {
        categories: [1, 2],
      },
      title: {
        text: "Compression Rate Analytics (Time vs Cpm)",
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
        offsetX: 40,
      },
      colors: ["rgb(255,212,38)", "rgb(48,219,91)", "rgb(255,105,97)"],
    };

    let lessRate: number[] = [];
    let normalRate: number[] = [];
    let exceedRate: number[] = [];

    this.state.ratePerMinute.map((eachData: any) => {
      if (eachData.rate < 100) {
        lessRate.push(eachData.rate.toFixed(2));
        normalRate.push(0);
        exceedRate.push(0);
      } else if (eachData.rate <= 120) {
        lessRate.push(0);
        normalRate.push(eachData.rate.toFixed(2));
        exceedRate.push(0);
      } else {
        lessRate.push(0);
        normalRate.push(0);
        exceedRate.push(eachData.rate.toFixed(2));
      }
      return null;
    });
    const rateSeries = [
      {
        name: "Less than 100-120cpm",
        data: lessRate,
      },
      {
        name: "100-120cpm",
        data: normalRate,
      },
      {
        name: "More than 100-120cpm",
        data: exceedRate,
      },
    ];
    return (
      <ReactApexChart
        options={rateOptions}
        series={rateSeries}
        type="bar"
        height={250}
      />
    );
  };
  render() {
    return (
      <div className="mt-8">
        <div className="max-w-6xl mx-auto my-4 px-4 sm:px-6 lg:px-8">
          <div className="flex flex-row items-center">
            <div
              className="cursor-pointer group flex flex-row items-center"
              onClick={this.handleBack}
            >
              <ChevronLeftIcon className="h-7 w-7 mr-1 text-blue-600 group-hover:text-blue-500" />
              <h1 className="text-xl font-bold leading-tight text-blue-600 group-hover:text-blue-500">
                Records
              </h1>
            </div>
            <h1 className="text-xl font-bold leading-tight text-gray-700 ml-2">
              / {this.state.record.name ?? "Record's Details"}
            </h1>
          </div>
          <div className="mt-4 bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
              <dl className="sm:divide-y sm:divide-gray-200">
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Total Compression Performed
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {this.state.analyticsAttribute
                      ? this.state.analyticsAttribute.baseline +
                        this.state.analyticsAttribute.successful +
                        this.state.analyticsAttribute.deep
                      : 0}
                  </dd>
                </div>

                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Compression below 5cm
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {this.state.analyticsAttribute
                      ? this.state.analyticsAttribute.baseline
                      : 0}
                  </dd>
                </div>

                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Compression with 5-6cm
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {this.state.analyticsAttribute
                      ? this.state.analyticsAttribute.successful
                      : 0}
                  </dd>
                </div>

                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Compression more than 5-6cm
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {this.state.analyticsAttribute
                      ? this.state.analyticsAttribute.deep
                      : 0}
                  </dd>
                </div>

                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Average Compression per minute
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {this.state.analyticsAttribute
                      ? Number(this.state.analyticsAttribute.cpm).toFixed(2)
                      : 0}
                  </dd>
                </div>

                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Total Score Achieved
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {this.state.analyticsAttribute
                      ? Number(this.state.analyticsAttribute.score).toFixed(2)
                      : 0}
                    %
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:gap-4 sm:px-6 ">
                  {this.renderDepthGraph()}
                </div>
                <div className="py-4 sm:py-5 sm:gap-4 sm:px-6 ">
                  {this.renderRateGraph()}
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
