import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { RecordModel } from "../../models/Record";
import { UserModel } from "../../models/User";
import { auth, firestore } from "../../utils/firebase";

export const recordDataLimit: number = 10;

export const getRecordWithPagination = (
  paginationStartAfter: string | Date
) => {
  return async (dispatch: any, getState: any) => {
    try {
      let recordSnapshot;
      let recordCollectionQuery = query(
        collection(firestore, "records"),
        limit(recordDataLimit)
      );

      recordCollectionQuery = query(
        recordCollectionQuery,
        orderBy("createdAt", "desc")
      );

      let recordList: RecordModel[] = getState().recordStore.records;
      const usersList: UserModel[] = getState().userStore.users;

      const newRecordList: RecordModel[] = [];

      if (!paginationStartAfter) {
        recordList = [];
      }

      updateRecordLoadingState(dispatch, true);

      if (paginationStartAfter) {
        recordSnapshot = await getDocs(
          query(recordCollectionQuery, startAfter(paginationStartAfter))
        );
      } else {
        recordSnapshot = await getDocs(recordCollectionQuery);
      }

      const userIdList: string[] = [];

      if (recordSnapshot) {
        recordSnapshot.forEach((eachDoc: any) => {
          const eachRecord = eachDoc.data() as RecordModel;
          newRecordList.push(eachRecord);

          let userPresent = false;
          usersList.map((eachUser) => {
            if (eachUser.id === eachRecord.userId) {
              userPresent = true;
            }
            return null;
          });

          if (!userPresent) {
            if (!userIdList.includes(eachRecord.userId)) {
              userIdList.push(eachRecord.userId);
            }
          }
        });

        if (newRecordList.length > 0) {
          let lastCursor: string | Date = "";
          if (newRecordList.length === recordDataLimit) {
            lastCursor = newRecordList[recordDataLimit - 1].createdAt;
          }

          dispatch({
            type: "UPDATE_RECORD_LIST",
            payload: {
              records: recordList.concat(newRecordList),
              lastCursor: lastCursor,
            },
          });
        }
      } else {
        dispatch({
          type: "UPDATE_RECORD_LIST",
          payload: {
            records: recordList,
            lastCursor: "",
          },
        });
      }
      const newUserList: UserModel[] = [];

      if (userIdList.length > 0) {
        while (userIdList.length > 0) {
          const spliceUserIdList = userIdList.splice(0, recordDataLimit);
          const userSnapshot = await getDocs(
            query(
              collection(firestore, "users"),
              where("id", "in", spliceUserIdList)
            )
          );

          if (userSnapshot) {
            userSnapshot.forEach((eachUser: any) => {
              const eachUserData = eachUser.data() as UserModel;
              newUserList.push(eachUserData);
            });
          }
        }

        dispatch({
          type: "UPDATE_USER_LIST",
          payload: {
            users: usersList.concat(newUserList),
          },
        });
      }
      updateRecordLoadingState(dispatch, false);
    } catch (err: any) {
      updateRecordLoadingState(dispatch, false);
      return err.message;
    }
  };
};

export const getRecord = async (selectedId: string) => {
  try {
    if (auth.currentUser?.uid && selectedId) {
      const recordQuery = await getDoc(doc(firestore, "records", selectedId));

      if (recordQuery.exists()) {
        return recordQuery.data();
      } else {
        return "Record cannot be found";
      }
    } else {
      return "Unknown error, please contact developer at developer@blixify.co if this continues";
    }
  } catch (err: any) {
    return "Unknown error, please contact developer at developer@blixify.co if this continues";
  }
};

export const clearRecords = () => {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: "UPDATE_RECORD_LIST",
      payload: {
        records: [],
        lastCursor: "",
      },
    });
  };
};

const updateRecordLoadingState = (dispatch: any, loading: boolean) => {
  dispatch({
    type: "UPDATE_RECORD_LOADING",
    payload: {
      loading: loading,
    },
  });
};
