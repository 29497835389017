import {
  collection,
  deleteField,
  doc,
  endAt,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  startAt,
  updateDoc,
} from "firebase/firestore";
import { Role, UserModel } from "../../models/User";
import { auth, firestore } from "../../utils/firebase";

export const userDataLimit: number = 10;

export const getUsersWithPagination = (
  paginationStartAfter: string | Date,
  paginationSearch: string
) => {
  return async (dispatch: any, getState: any) => {
    try {
      let userSnapshot;
      let userCollectionQuery = query(
        collection(firestore, "users"),
        limit(userDataLimit)
      );

      if (paginationSearch) {
        userCollectionQuery = query(userCollectionQuery, orderBy("email"));
      } else {
        userCollectionQuery = query(
          userCollectionQuery,
          orderBy("createdAt", "desc")
        );
      }

      let userList: UserModel[] = getState().userStore.users;
      const newUserList: UserModel[] = [];

      if (!paginationStartAfter) {
        userList = [];
      }

      updateUserLoadingState(dispatch, true);

      if (paginationSearch) {
        userSnapshot = await getDocs(
          query(
            userCollectionQuery,
            startAt(paginationSearch),
            endAt(paginationSearch + "\uf8ff")
          )
        );
      } else {
        if (paginationStartAfter) {
          userSnapshot = await getDocs(
            query(userCollectionQuery, startAfter(paginationStartAfter))
          );
        } else {
          userSnapshot = await getDocs(userCollectionQuery);
        }
      }

      if (userSnapshot) {
        userSnapshot.forEach((eachDoc: any) => {
          const eachUser = eachDoc.data() as UserModel;
          newUserList.push(eachUser);
        });
      }

      if (newUserList.length > 0) {
        let lastCursor: string | Date = "";
        if (newUserList.length === userDataLimit) {
          lastCursor = newUserList[userDataLimit - 1].createdAt;
        }

        if (paginationSearch) {
          lastCursor = "";
        }

        dispatch({
          type: "UPDATE_USER_LIST",
          payload: {
            users: userList.concat(newUserList),
            lastCursor: lastCursor,
          },
        });
      } else {
        dispatch({
          type: "UPDATE_USER_LIST",
          payload: {
            users: userList,
            lastCursor: "",
          },
        });
      }

      updateUserLoadingState(dispatch, false);
    } catch (err: any) {
      updateUserLoadingState(dispatch, false);
      return err.message;
    }
  };
};

export const getUser = async (selectedId: string) => {
  try {
    if (auth.currentUser?.uid && selectedId) {
      const userQuery = await getDoc(doc(firestore, "users", selectedId));

      if (userQuery.exists()) {
        return userQuery.data();
      } else {
        return "User cannot be found";
      }
    } else {
      return "Unknown error, please contact developer at developer@blixify.co if this continues";
    }
  } catch (err: any) {
    return "Unknown error, please contact developer at developer@blixify.co if this continues";
  }
};

export const updateUserRole = async (
  selectedId: string,
  role: keyof typeof Role
) => {
  try {
    if (auth.currentUser?.uid && selectedId) {
      const userRef = doc(firestore, "users", selectedId);
      if (role === "STUDENT") {
        await updateDoc(userRef, {
          role,
          teacher: deleteField(),
          studentId: [],
        });
      } else {
        await updateDoc(userRef, {
          role,
          teacher: true,
        });
      }
      return "";
    } else {
      return "Unknown error, please contact developer at developer@blixify.co if this continues";
    }
  } catch (err: any) {
    return "Unknown error, please contact developer at developer@blixify.co if this continues";
  }
};

export const clearUsers = () => {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: "UPDATE_USER_LIST",
      payload: {
        users: [],
        lastCursor: "",
      },
    });
  };
};

const updateUserLoadingState = (dispatch: any, loading: boolean) => {
  dispatch({
    type: "UPDATE_USER_LOADING",
    payload: {
      loading: loading,
    },
  });
};
