import React, { Component } from "react";
import { connect } from "react-redux";
import RecordController from "../components/controller/RecordController";
import UserController from "../components/controller/UserController";
import Header from "../components/dashboard/Header";
import { withRouter } from "../navigator/NavigateWrapper";
import SecurityNavigator from "../navigator/SecurityNavigator";
import { authStateInterface } from "../store/reducers/authReducer";
import Home from "./Home";
import LeftNav, { DashboardPage } from "./LeftNav";

interface Props {
  location: any;
  navigate: any;
  authStore: authStateInterface;
}

interface State {
  leftNavMobileMenu: boolean;
  page: DashboardPage;
}

class Dashboard extends Component<Props> {
  state: State = {
    leftNavMobileMenu: false,
    page: DashboardPage.HOME,
  };

  componentDidMount() {
    this.handlePageNavigation();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.handlePageNavigation();
    }
  }

  handlePageNavigation = () => {
    const path = window.location.pathname;
    let page: DashboardPage = DashboardPage.HOME;
    if (path.includes("dashboard/users")) {
      page = DashboardPage.USERS;
    } else if (path.includes("dashboard/userDetail")) {
      page = DashboardPage.USERDETAIL;
    } else if (path.includes("dashboard/recordDetails")) {
      page = DashboardPage.RECORDDETAILS;
    } else if (path.includes("dashboard/records")) {
      page = DashboardPage.RECORD;
    }
    this.setState({
      page,
    });
  };

  handlePathNavigation = (page: DashboardPage, id?: string) => {
    let path = "";
    switch (page) {
      case DashboardPage.USERS:
        if (id) {
          path = `/dashboard/users?data=${id}`;
        } else {
          path = "/dashboard/users";
        }
        break;
      case DashboardPage.USERDETAIL:
        path = `/dashboard/userDetail?data=${id}`;
        break;
      case DashboardPage.RECORD:
        path = `/dashboard/records`;
        break;
      case DashboardPage.RECORDDETAILS:
        path = `/dashboard/recordDetails?data=${id}`;
        break;
      default:
        break;
    }

    if (path) {
      this.props.navigate(path);
    }

    this.setState({
      page,
    });
  };

  handleToggleLeftNavMobileMenu = () => {
    this.setState({
      leftNavMobileMenu: !this.state.leftNavMobileMenu,
    });
  };

  renderContent = () => {
    switch (this.state.page) {
      case DashboardPage.HOME:
        return <Home />;
      case DashboardPage.USERS:
      case DashboardPage.USERDETAIL:
        return (
          <UserController
            pathname={this.props.location.pathname}
            handlePathNavigation={this.handlePathNavigation}
          />
        );
      case DashboardPage.RECORD:
      case DashboardPage.RECORDDETAILS:
        return (
          <RecordController
            pathname={this.props.location.pathname}
            handlePathNavigation={this.handlePathNavigation}
          />
        );
      default:
        return null;
    }
  };

  render() {
    return (
      <div className="h-screen flex overflow-hidden bg-gray-100">
        <SecurityNavigator location={this.props.location} />
        {!this.props.authStore.userLoading && this.props.authStore.userAuth && (
          <>
            <LeftNav
              leftNavMobileMenu={this.state.leftNavMobileMenu}
              handleToggleLeftNavMobileMenu={this.handleToggleLeftNavMobileMenu}
              page={this.state.page}
            />
            <div className="flex-1 overflow-auto focus:outline-none">
              <Header
                handleToggleLeftNavMobileMenu={
                  this.handleToggleLeftNavMobileMenu
                }
              />
              <main className="flex-1 relative pb-8 z-0 overflow-y-auto">
                {this.renderContent()}
              </main>
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
  };
};

export default connect(mapStateToProps)(withRouter(Dashboard));
