import {
  BellIcon,
  CheckCircleIcon,
  UserGroupIcon,
} from "@heroicons/react/outline";
import moment from "moment";
import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import DailyAnalyticsModel from "../models/DailyAnalytics";
import { Language } from "../models/User";
import { getDailyAnalytics } from "../store/actions/analyticsActions";

interface State {
  chartContainerSize: number;
  analytics: Analytics;
  weeklyAnalytics: DailyAnalyticsModel[];
}

interface Analytics {
  userNumber: number;
  createdDate: Date;
}

class Home extends Component {
  state: State = {
    chartContainerSize: 0,
    analytics: {
      userNumber: 0,
      createdDate: new Date(),
    },

    weeklyAnalytics: [],
  };

  componentDidMount = async () => {
    this.handleChartSize();
    window.addEventListener("resize", this.handleChartSize);
    const analytics = await getDailyAnalytics();
    if (analytics && typeof analytics !== "string") {
      let totalUser = analytics.codes.length;
      this.setState({
        analytics: {
          userNumber: totalUser,
          createdDate: new Date(),
        },
      });
    }
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleChartSize);
  }

  handleChartSize = () => {
    const chartContainer =
      document.getElementById("chartContainer")?.offsetWidth ?? 0;
    this.setState({
      chartContainerSize: chartContainer * 0.95,
    });
  };

  renderDashboardContent = () => {
    const content = [
      {
        icon: <UserGroupIcon className="h-5 w-5 text-white" />,
        title: "Registered Users",
        content: this.state.analytics.userNumber,
        span: `Updated on: ${moment(this.state.analytics.createdDate).format(
          "DD/MM/YYYY ha"
        )}`,
        fontSize: "text-lg",
        database: true,
      },
      {
        icon: <CheckCircleIcon className="h-5 w-5 text-white" />,
        title: "Active Users",
        content: Math.ceil(this.state.analytics.userNumber * 0.1),
        span: `Updated on: ${moment(this.state.analytics.createdDate).format(
          "DD/MM/YYYY ha"
        )}`,
        fontSize: "text-lg",
        viewBox: "0 0 24 24",
        inverted: true,
      },
      {
        icon: <BellIcon className="h-5 w-5 text-white" />,
        title: "Training Hours",
        content: Math.ceil(this.state.analytics.userNumber * 5),
        span: `Updated on: ${moment(this.state.analytics.createdDate).format(
          "DD/MM/YYYY ha"
        )}`,
        fontSize: "text-lg",
        viewBox: "0 0 24 24",
        inverted: true,
      },
    ];

    const contentViewList: any = [];

    content.map((eachContent, key: number) => {
      contentViewList.push(
        <div className="bg-white overflow-hidden shadow rounded-lg" key={key}>
          <div className="p-5">
            <div className="flex">
              <div className="flex-shrink-0">
                <span className="flex p-2 mt-1 rounded-lg bg-blue-500">
                  {eachContent.icon}
                </span>
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-md font-medium text-gray-500 truncate">
                    {eachContent.title}{" "}
                    {!eachContent.database && (
                      <span className="text-red-500">(SAMPLE)</span>
                    )}
                  </dt>
                  <dd>
                    <p className="text-xs font-medium text-gray-500 truncate">
                      {eachContent.span}
                    </p>
                    <div
                      className={`${eachContent.fontSize} font-medium text-gray-900 mt-2`}
                    >
                      {eachContent.content}
                    </div>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      );
      return null;
    });
    return contentViewList;
  };

  renderChart = () => {
    const languageList = Object.keys(Language).map((eachLanguage) => {
      return eachLanguage;
    });

    const series = [
      {
        name: "Registered Users",
        data: [
          Math.ceil(this.state.analytics.userNumber * 0.4),
          Math.ceil(this.state.analytics.userNumber * 0.6),
        ],
      },
    ];

    const options = {
      colors: ["#EF4444", "#F59E0B"],
      plotOptions: {
        bar: {
          columnWidth: "80%",
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: languageList,
      },
    };

    return (
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={400}
        width={this.state.chartContainerSize}
      />
    );
  };

  render() {
    return (
      <div className="mt-8">
        <div className="max-w-6xl mx-auto mt-5 px-4 sm:px-6 lg:px-8">
          <h2 className="text-xl leading-6 font-bold text-gray-900">
            Overview
          </h2>
          <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-3">
            {this.renderDashboardContent()}
          </div>
        </div>

        <div className="max-w-6xl mx-auto mt-8 px-4 sm:px-6 lg:px-8">
          <h2 className="text-xl leading-6 font-bold text-gray-900 ">
            Total Registered Users of Q-Cat CPR Malaysia{" "}
            <span className="text-red-500 font-medium">(SAMPLE)</span>
          </h2>
          <div
            className="mt-2 bg-white overflow-hidden shadow rounded-lg flex justify-center items-center"
            id="chartContainer"
          >
            <div className="mx-auto mt-8 px-4">{this.renderChart()}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
