import { MenuIcon } from "@heroicons/react/outline";
import React, { Component } from "react";
import { handleSignOut } from "../../store/actions/authActions";
import IconButton from "../base/IconButton";
import Logo from "./../../images/logo.png";

interface Props {
  handleToggleLeftNavMobileMenu: () => void;
}

class Header extends Component<Props> {
  state = {
    popOverMenu: false,
  };

  handlePopOverMenu = () => {
    this.setState({
      popOverMenu: !this.state.popOverMenu,
    });
  };

  handleSignOutAdmin = async () => {
    await handleSignOut();
  };

  renderPopOverItems = (link: string, title: string) => {
    if (link && title) {
      return (
        <span
          className="block px-4 py-2 text-sm text-gray-700 cursor-pointer"
          onClick={this.handleSignOutAdmin}
        >
          {title}
        </span>
      );
    }
  };

  render() {
    return (
      <div className="relative flex-shrink-0 flex h-16 bg-white shadow border-b border-gray-200 lg:border-none">
        <IconButton
          icon={<MenuIcon className="h-5 w-5 text-gray-400" />}
          className="px-4 border-r rounded-md border-gray-200 lg:hidden"
          onClick={this.props.handleToggleLeftNavMobileMenu}
        />
        <div className="flex-1 px-4 flex justify-between sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
          <div className="flex-1 flex" />
          <div className="ml-4 flex items-center md:ml-6">
            <div className="ml-3 relative">
              <div>
                <button
                  type="button"
                  onBlur={() => {
                    setTimeout(() => {
                      this.handlePopOverMenu();
                    }, 200);
                  }}
                  className="max-w-xs bg-white rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 lg:p-2 lg:rounded-md lg:hover:bg-gray-50"
                  aria-expanded="false"
                  aria-haspopup="true"
                  onClick={this.handlePopOverMenu}
                >
                  <img
                    className="h-8 w-8 rounded-full object-cover"
                    src={Logo}
                    alt=""
                  />
                </button>
              </div>

              {this.state.popOverMenu && (
                <div
                  className="origin-top-right z-10 absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="user-menu-button"
                  tabIndex={1}
                >
                  {this.renderPopOverItems("/", "Logout")}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
