import React, { Component, ReactElement } from "react";

interface Props {
  icon: ReactElement;
  disabled?: boolean;
  className?: string;
  background?: string;
  onClick?: (e: any) => void;
}

export default class IconButton extends Component<Props> {
  render() {
    if (this.props.icon) {
      let defaultClassName =
        "p-2 inline-flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-inset";
      if (this.props.className) {
        defaultClassName += ` ${this.props.className}`;
      }
      if (this.props.background) {
        defaultClassName += ` ${this.props.background}`;
      } else {
        defaultClassName +=
          " text-gray-400 hover:text-gray-500 hover:bg-gray-100 bg-white focus:ring-blue-500";
      }

      return (
        <button
          disabled={this.props.disabled}
          type="button"
          className={defaultClassName}
          aria-expanded="false"
          onClick={this.props.onClick}
        >
          <span className="sr-only">Open menu</span>
          {this.props.icon}
        </button>
      );
    } else {
      return null;
    }
  }
}
