import { Component } from "react";
import { connect } from "react-redux";
import { DashboardPage } from "../../pages/LeftNav";
import UserEditor from "../../pages/UserDetails";
import Users from "../../pages/Users";
import {
  clearUsers,
  getUsersWithPagination,
} from "../../store/actions/userActions";
import { authStateInterface } from "../../store/reducers/authReducer";

interface Props {
  pathname: string;
  authStore: authStateInterface;
  handlePathNavigation: (path: DashboardPage, id?: string) => void;
  getUsersWithPagination: (
    paginationStartAt: string | Date,
    paginationSearch: string
  ) => void;
  clearUsers: () => void;
}

interface State {
  pageIndex: number;
  searchText: string;
}

class UserController extends Component<Props> {
  searchTimeout: ReturnType<typeof setTimeout> | null = null;
  state: State = {
    pageIndex: 1,
    searchText: "",
  };

  componentDidMount = () => {
    this.props.getUsersWithPagination("", "");
  };

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.pathname !== this.props.pathname) {
      const searchParams = new URLSearchParams(window.location.search);
      const selectedId = searchParams.get("data");
      if (selectedId === "r") {
        this.handleReupdateData();
      }
    }
  };

  componentWillUnmount() {
    this.props.clearUsers();
  }

  handleReupdateData = () => {
    this.props.clearUsers();
    this.handleUpdatePageIndex(1);
    this.props.getUsersWithPagination("", "");
  };

  handleUpdatePageIndex = (pageIndex: number) => {
    this.setState({ pageIndex });
  };

  handleGetPreviewData = async (type?: string) => {
    if (this.props.authStore.user || type === "search") {
      this.props.getUsersWithPagination("", this.state.searchText);
    }
  };

  handleFilter = (e: any) => {
    this.setState({ searchText: e.target.value }, () => {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }

      this.searchTimeout = setTimeout(() => {
        this.handleGetPreviewData("search");
        this.handleUpdatePageIndex(1);
      }, 500);
    });
  };

  render() {
    if (this.props.pathname === "/dashboard/users") {
      return (
        <Users
          pageIndex={this.state.pageIndex}
          searchText={this.state.searchText}
          handlePathNavigation={this.props.handlePathNavigation}
          handleUpdatePageIndex={this.handleUpdatePageIndex}
          getUsersWithPagination={this.props.getUsersWithPagination}
          handleFilter={this.handleFilter}
        />
      );
    } else if (this.props.pathname === "/dashboard/userDetail") {
      return (
        <UserEditor handlePathNavigation={this.props.handlePathNavigation} />
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    clearUsers: () => dispatch(clearUsers()),
    getUsersWithPagination: (
      paginationStartAt: string | Date,
      paginationSearch: string
    ) => dispatch(getUsersWithPagination(paginationStartAt, paginationSearch)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UserController);
