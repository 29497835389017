import { ChevronLeftIcon } from "@heroicons/react/solid";
import moment from "moment";
import { Component } from "react";
import Button from "../components/base/Button";
import { Language, Role, UserModel } from "../models/User";
import { getUser, updateUserRole } from "../store/actions/userActions";
import { DashboardPage } from "./LeftNav";

interface State {
  user: UserModel;
}

interface Props {
  handlePathNavigation: (path: DashboardPage, id?: string) => void;
}

export default class UserEditor extends Component<Props> {
  state: State = {
    user: {
      createdAt: new Date(),
      email: "",
      name: "",
      language: "EN",
      code: "",
      id: "",
      role: "STUDENT",
      studentId: [],
    },
  };

  componentDidMount = async () => {
    const searchParams = new URLSearchParams(window.location.search);
    const selectedId = searchParams.get("data");
    if (selectedId) {
      const userData = await getUser(selectedId);
      if (typeof userData === "string") {
        this.handleBack();
      } else {
        this.setState({
          user: userData,
        });
      }
    } else {
      this.handleBack();
    }
  };

  handleBack = () => {
    this.props.handlePathNavigation(DashboardPage.USERS);
  };

  handleUpdateUserRole = async () => {
    let role: keyof typeof Role = "STUDENT";
    if (this.state.user.role === "STUDENT") {
      role = "TEACHER";
    }
    await updateUserRole(this.state.user.id, role);
    this.props.handlePathNavigation(DashboardPage.USERS, "r");
  };

  render() {
    return (
      <div className="mt-8">
        <div className="max-w-6xl mx-auto my-4 px-4 sm:px-6 lg:px-8">
          <div className="flex flex-row items-center">
            <div
              className="cursor-pointer group flex flex-row items-center"
              onClick={this.handleBack}
            >
              <ChevronLeftIcon className="h-7 w-7 mr-1 text-blue-600 group-hover:text-blue-500" />
              <h1 className="text-xl font-bold leading-tight text-blue-600 group-hover:text-blue-500">
                Users
              </h1>
            </div>
            <h1 className="text-xl font-bold leading-tight text-gray-700 ml-2">
              / User's Detail
            </h1>
            <div className="flex-grow" />

            <Button
              text={
                this.state.user.role === "TEACHER"
                  ? "Downgrade Role"
                  : "Upgrade Role"
              }
              type="normal"
              onClick={this.handleUpdateUserRole}
            />
          </div>
          <div className="mt-4 bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
              <dl className="sm:divide-y sm:divide-gray-200">
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Email</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {this.state.user.email}
                  </dd>
                </div>

                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Name</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {this.state.user.name}
                  </dd>
                </div>

                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Code</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {this.state.user.code}
                  </dd>
                </div>

                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Language
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {Language[this.state.user.language]}
                  </dd>
                </div>

                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Role</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {Role[this.state.user.role]}
                  </dd>
                </div>

                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Creation Date
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {moment(this.state.user.createdAt.seconds * 1000).format(
                      "MM/DD/YYYY"
                    )}
                  </dd>
                </div>

                {this.state.user.role === "TEACHER" && (
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Number of Student
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {this.state.user.studentId?.length ?? 0}
                    </dd>
                  </div>
                )}
              </dl>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
