import { RecordModel } from "../../models/Record";

export interface AnalyticsAttribute {
  baseline: number;
  successful: number;
  deep: number;
  cpm?: number;
  score: number;
}

export interface CompressionPerMinute {
  lowCompression: number;
  normalCompression: number;
  deepCompression: number;
}

export interface recordStateInterface {
  records: RecordModel[];
  lastCursor: string | Date;
  loading: boolean;
}

const initState: recordStateInterface = {
  records: [],
  lastCursor: "",
  loading: true,
};

const recordReducer: any = (state = initState, action: any) => {
  switch (action.type) {
    case "UPDATE_RECORD_LIST":
      return {
        ...state,
        records: action.payload.records,
        lastCursor: action.payload.lastCursor,
      };
    case "UPDATE_RECORD_LOADING":
      return {
        ...state,
        loading: action.payload.loading,
      };
    default:
      return state;
  }
};

export default recordReducer;
