import { doc, getDoc } from "firebase/firestore";
import { CodeModel } from "../../models/Code";
import { auth, firestore } from "../../utils/firebase";

export const getDailyAnalytics = async () => {
  try {
    if (auth.currentUser?.uid) {
      const analyticsRef = doc(firestore, "codes", "alpha");
      const analyticsSnapshot = await getDoc(analyticsRef);
      return analyticsSnapshot.data() as CodeModel;
      // let selectedAnalytics: DailyAnalyticsModel | null = null;
      // if (analyticsSnapshot) {
      //   analyticsSnapshot.forEach((eachAnalytics) => {
      //     const dummyAnalytics = eachAnalytics.data() as DailyAnalyticsModel;
      //     dummyAnalytics["createdDate"] = moment(
      //       dummyAnalytics.createdDate.seconds * 1000
      //     ).toDate();
      //     selectedAnalytics = dummyAnalytics;
      //   });
      // }
    } else {
      return "Unknown error, please contact developer at developer@blixify.co if this continues";
    }
  } catch (err) {
    return "Unknown error, please contact developer at developer@blixify.co if this continues";
  }
};
