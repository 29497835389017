import { Component } from "react";
import { connect } from "react-redux";
import { DashboardPage } from "../../pages/LeftNav";
import Record from "../../pages/Record";
import { RecordDetails } from "../../pages/RecordDetails";
import {
  clearRecords,
  getRecordWithPagination,
} from "../../store/actions/recordActions";
import { clearUsers } from "../../store/actions/userActions";
import { authStateInterface } from "../../store/reducers/authReducer";

interface Props {
  pathname: string;
  authStore: authStateInterface;
  handlePathNavigation: (path: DashboardPage, id?: string) => void;
  getRecordWithPagination: (paginationStartAt: string | Date) => void;
  clearRecords: () => void;
  clearUsers: () => void;
}

interface State {
  pageIndex: number;
}

class AnalyticController extends Component<Props> {
  searchTimeout: ReturnType<typeof setTimeout> | null = null;
  state: State = {
    pageIndex: 1,
  };

  componentDidMount = () => {
    this.props.getRecordWithPagination("");
  };

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.pathname !== this.props.pathname) {
      const searchParams = new URLSearchParams(window.location.search);
      const selectedId = searchParams.get("data");
      if (selectedId === "r") {
        this.handleReupdateData();
      }
    }
  };

  componentWillUnmount() {
    this.props.clearRecords();
    this.props.clearUsers();
  }

  handleReupdateData = () => {
    this.props.clearRecords();
    this.props.clearUsers();
    this.handleUpdatePageIndex(1);
    this.props.getRecordWithPagination("");
  };

  handleUpdatePageIndex = (pageIndex: number) => {
    this.setState({ pageIndex });
  };

  handleGetPreviewData = async (type?: string) => {
    if (this.props.authStore.user) {
      this.props.getRecordWithPagination("");
    }
  };

  render() {
    if (this.props.pathname === "/dashboard/records") {
      return (
        <Record
          pageIndex={this.state.pageIndex}
          handlePathNavigation={this.props.handlePathNavigation}
          handleUpdatePageIndex={this.handleUpdatePageIndex}
          getRecordWithPagination={this.props.getRecordWithPagination}
        />
      );
    } else if (this.props.pathname === "/dashboard/recordDetails") {
      return (
        <RecordDetails handlePathNavigation={this.props.handlePathNavigation} />
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    clearRecords: () => dispatch(clearRecords()),
    clearUsers: () => dispatch(clearUsers()),
    getRecordWithPagination: (paginationStartAt: string | Date) =>
      dispatch(getRecordWithPagination(paginationStartAt)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AnalyticController);
