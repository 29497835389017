import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import Input from "../components/base/Input";
import Table, { TableHeader } from "../components/base/Table";
import { Language, Role } from "../models/User";
import { userDataLimit } from "../store/actions/userActions";
import { authStateInterface } from "../store/reducers/authReducer";
import { userStateInterface } from "../store/reducers/userReducer";
import { DashboardPage } from "./LeftNav";

interface Props {
  authStore: authStateInterface;
  userStore: userStateInterface;
  pageIndex: number;
  searchText: string;
  handlePathNavigation: (path: DashboardPage, id?: string) => void;
  getUsersWithPagination: (
    paginationStartAt: string | Date,
    paginationSearch: string
  ) => void;
  handleUpdatePageIndex: (pageIndex: number) => void;
  handleFilter: (e: any) => void;
}

class Users extends Component<Props> {
  handleSelectedData = (id: string) => {
    this.props.handlePathNavigation(DashboardPage.USERDETAIL, id);
  };

  handlePagination = (value: string) => {
    if (value === "next") {
      if (
        this.props.userStore.lastCursor &&
        this.props.pageIndex * userDataLimit + 1 >
          this.props.userStore.users.length
      ) {
        this.props.getUsersWithPagination(this.props.userStore.lastCursor, "");
      }
      this.props.handleUpdatePageIndex(this.props.pageIndex + 1);
    } else {
      this.props.handleUpdatePageIndex(this.props.pageIndex - 1);
    }
  };

  renderData = () => {
    const dataList: any[] = [];
    if (this.props.userStore.users.length > 0) {
      this.props.userStore.users.map((eachUser) => {
        dataList.push({
          id: eachUser.id,
          email: eachUser.email,
          name: eachUser.name,
          language: Language[eachUser.language],
          code: eachUser.code,
          role: Role[eachUser.role],
          createdAt: moment(eachUser.createdAt.seconds * 1000).format(
            "DD/MM/YYYY"
          ),
        });
        return null;
      });
    }
    return dataList;
  };

  render() {
    const headers: TableHeader[] = [
      {
        key: "id",
        title: "Id",
      },
      {
        key: "email",
        title: "Email",
      },
      {
        key: "name",
        title: "Name",
      },
      {
        key: "code",
        title: "Code",
      },
      {
        key: "language",
        title: "Language",
      },
      {
        key: "role",
        title: "Role",
      },

      {
        key: "createdAt",
        title: "Created At",
      },
    ];

    return (
      <div className="mt-8">
        <div className="max-w-6xl mx-auto my-4 px-4 sm:px-6 lg:px-8">
          <div className="flex items-center">
            <h3 className="text-xl leading-6 font-bold text-gray-900">Users</h3>
            <div className="flex-grow" />
            <div className="ml-4 w-60 sm:w-80">
              <Input
                id="id"
                placeholder="Search by Email"
                value={this.props.searchText}
                onChange={this.props.handleFilter}
              />
            </div>
          </div>
        </div>

        <Table
          dataLimit={userDataLimit}
          header={headers}
          loading={this.props.userStore.loading}
          pageIndex={this.props.pageIndex}
          lastCursor={this.props.userStore.lastCursor}
          data={this.renderData()}
          onClickRow={this.handleSelectedData}
          handlePage={this.handlePagination}
          newTabUrl="/dashboard/userDetail?data="
        />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
    userStore: state.userStore,
  };
};

export default connect(mapStateToProps)(Users);
