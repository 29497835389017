import { UserModel } from "../../models/User";

export interface userStateInterface {
  users: UserModel[];
  lastCursor: string | Date;
  loading: boolean;
}

const initState: userStateInterface = {
  users: [],
  lastCursor: "",
  loading: true,
};

const userReducer: any = (state = initState, action: any) => {
  switch (action.type) {
    case "UPDATE_USER_LIST":
      return {
        ...state,
        users: action.payload.users,
        lastCursor: action.payload.lastCursor,
      };
    case "UPDATE_USER_LOADING":
      return {
        ...state,
        loading: action.payload.loading,
      };
    default:
      return state;
  }
};

export default userReducer;
